@import '../../styles/_variables';

.Card {
  background: white;
  box-shadow: $boxShadowSoft;
  position: relative;
  display: flex;
  flex-direction: column;
  header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding: 20px;
    h2 {
      margin: 0;
      flex: 1 1 auto;
      small {
        display: block;
        color: $mediumGray;
        font-size: 0.85rem;
        font-weight: normal;
        line-height: 1em;
      }
    }
    .headerActions {
      flex: 0 1 auto;
    }
  }
  .cardBody {
    position: relative;
    flex: 1 1 auto;
    padding: 0.5em 20px;
    display: flex;
    justify-content: center;
    span.emptyStateMessage {
      color: $mediumLightGray;
      font-size: 1.15em;
      margin: 2rem auto;
      text-align: center;
    }
    .ui.active.loader {
      margin: 2rem 0;
    }
  }
  footer {
    flex: 0 0 auto;
    border-top: 1px solid #e0e3ec;
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      color: $mediumGray;
      text-decoration: none;
      font-weight: 500;
      font-size: 1em;
      .icon.chevron.right {
        margin-left: 4px;
        transform: translateX(0);
        transition: transform 0.25s ease-in-out;
      }
      &:hover {
        color: $mediumGray;
        .icon.chevron.right {
          transform: translateX(3px);
          transition: transform 0.25s ease-in-out;
        }
      }
    }
  }
}
