@import '../../styles/_variables.scss';

.Dashboard {
  .header-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .header-widget {
      width: 32%;
      display: flex;
      justify-content: space-between;

      .left-box {
        width: 70%;
        padding: 5px 0;

        .title {
          font-size: 12px;
        }
        .subtitle {
          font-size: 10px;
          color: $gray;
        }
      }
      .right-box {
        width: 30%;
        text-align: right;
        font-size: 28px;
        align-self: center;
      }
    }
  }
}
