.HeaderBar {
  padding: 10px 0 20px;
  width: 100%;
  position: relative;
  clear: both;
  overflow: hidden;

  .header-bar-title {
    display: inline-block;
    float: left;
    padding-right: 20px;
  }
  .left-actions {
    float: left;
  }
  .right-actions {
    float: right;
  }
  .header-bar-actions {
    min-width: 50%;
    disply: flex;
    justify-content: space-between;
    align-self: center;
  }
}
