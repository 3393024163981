@import '../../styles/_variables';

.DeleteConfirmModal {
  .button-box {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
