@import '../../styles/_variables';

.ClientTabApplications {
  .Card {
    box-shadow: none;
    padding-top: 20px;
    header {
      padding: 0;
    }
    .cardBody {
      padding: 0;
    }
  }
  &.ui.segment {
    width: 100%;
    box-shadow: none;
    border: 0;
    padding: 0;
  }
  .btn-secondary {
    z-index: 100;
    position: relative;
  }
  .header-row {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .filters {
      display: inline-block;

      .FormSelect {
        margin-right: 20px;
      }
    }
  }
  .empty-text {
    text-align: center;
    padding: 30px 0;
    color: $gray;
  }
}
