@import '../../styles/_variables';

.FormSelect {
  .component {
    height: 34px;

    input::placeholder {
      opacity: 1;
    }
  }
  .selected-component {
    padding: 8px;
    cursor: pointer;
    position: relative;
    width: 100%;
  }
  .options-container {
    z-index: 100;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 34px;
    left: 0px;
    background: #fff;
    box-shadow: $boxShadowSoft;
    border-radius: 2px;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;

    li {
      padding: 8px;
      cursor: pointer;

      &:hover {
        background: lighten($lightGray, 10%);
      }

      &.focused {
        background: lighten($lightGray, 10%);
      }
      &.selected {
        background: $mediumLightGray;
      }
      &.empty {
        padding: 16px;
      }
    }
    i.icon {
      cursor: pointer;
    }

    &.disabled {
      .icon {
        cursor: default;
      }
    }
  }
}
