@import '../../styles/_variables.scss';
.HeadOfHousehold {
  width: 500px;

  .taken {
    color: $lightGray;
    font-style: italic;
  }
  .ui.selectable.table tbody tr.taken:hover,
  .ui.table tbody tr.taken td.selectable:hover {
    background: #fff !important;
    color: $lightGray !important;
  }
  .scroll-table {
    height: 200px;
    overflow: auto;
    margin-right: -13px;
    padding-right: 13px;
  }
}
