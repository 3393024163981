@import '../../styles/_variables.scss';
.HouseholdDetails {
  .cardBody {
    display: block;
  }
  .overview {
    margin-bottom: 20px;
  }
  .form-row {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    clear: both;

    & .FormField {
      float: left;
      width: 19%;
    }
    .upcase .title {
      text-transform: uppercase;
    }
    .multiline .value {
      white-space: pre;
    }
  }
}
