.HouseholdAdd {
  .cardBody {
    display: block;
    padding-bottom: 30px;
  }
  .form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &.left-aligned {
      display: block;
      > :not(:first-child) {
        margin-left: 20px;
      }
    }
    &.bump-top {
      margin-top: 30px;
    }

    .navigator {
      .options-container {
      }
    }

    .options-container {
      max-height: 235px;
    }

    .quarter {
      width: 23%;
      > * {
        width: 100%;
      }
      .ui.checkbox {
        margin-top: 8px;
      }
    }
    .address-1 {
      width: 26%;
    }
    .address-2 {
      width: 22%;
    }
    .city {
      width: 18%;
    }
    .state,
    .zip {
      width: 12%;
    }
  }
}
