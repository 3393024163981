@import '../../styles/_variables.scss';

.Login {
  width: 350px;
  .FormInput {
    width: 100%;
    margin-top: 20px;
  }

  .form-actions {
    display: flex;
    margin-top: 35px;
    flex-direction: column;
    justify-content: center;

    .ui.button {
      width: 180px;
      margin: 5px auto 0;
    }
  }
}
