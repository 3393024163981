@import '../../styles/_variables';

.FormDate {
  .component {
    height: 34px;
    .date-selector {
      padding: 0;
      line-height: 1;
      input {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
