@import '../../styles/_variables';

.TabNotes {
  &.ui.segment {
    width: 100%;
    box-shadow: none;
    border: 0;
    padding: 0;
  }
  .note-filter-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .SearchInput {
      margin-left: 20px;
    }
  }
  .note-row {
    width: 100%;
    border-bottom: 2px solid $offWhite;
    &:last-child {
      border-bottom: none;
    }

    p {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .note-header {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      h5 {
        font-weight: bold;
        font-size: 12px;
      }
    }
    .note-meta {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .FormField {
        flex: none;
        margin-right: 4%;
      }
    }
  }
}
