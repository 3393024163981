@import '../../styles/_variables';

.Households {
  .empty-text {
    text-align: center;
    padding: 30px 0;
    color: $gray;
  }
  .well {
    background: #fff;
  }
}
