.DataTableSP {
  position: relative;
  width: 100%;
  .ui.sortable.table thead th.table-header-cell {
    cursor: initial;
  }
  .filterRow {
    display: flex;
    margin-top: 10px;
    margin-bottom: 1rem;
    justify-content: flex-end;
    & > * {
      margin-left: 0.75rem;
    }
    :first-child {
      margin-left: 0;
    }
  }
  .pagination {
    position: relative;
    display: block;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .ui.pointing.secondary.menu {
      border-bottom: none;
    }
  }
  .well {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    background: #f5f5f5;
    color: #8793a9;
    font-size: 14px;
  }
}

.Card.has-title {
  & > header {
    position: absolute;
    width: 50%;
    margin-bottom: 0;
    padding-bottom: 0;

    h2 {
      font-size: 16px;
    }
  }
  .filterRow {
    margin-bottom: 35px;
  }
  .well {
    margin-top: 60px;
  }
}
