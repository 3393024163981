@import '../../styles/_variables';

.app.headerbar {
  display: flex;
  background: white;
  box-shadow: $boxShadowSoft;
  flex: 0 0 60px;
  align-items: center;
  padding: 0 1.5rem;
  z-index: 10;

  .customerName {
    font-weight: 600;
  }
  .headerbarLeft {
    .ui.search {
      font-size: 1rem;
    }
  }
  .headerbarRight {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 2rem;
    > * {
      margin-left: 1rem;
      display: inherit;
      &:first-child {
        margin-left: 0;
      }
    }
    .userGreeting {
      font-size: 1rem;
      line-height: 1.125remx;

      .material-icon {
        vertical-align: bottom;
      }
    }
  }
}
