@import '../../styles/_variables.scss';
.SettingsDetails {
  .cardBody {
    flex-direction: column;
  }
  .options-list {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;

    li {
      margin-bottom: 10px;

      
      .option-input {
        width: 350px;
        margin-right: 10px;
      }

      .icon {
        color: $gray;
        cursor: pointer;
      }
    }
  }
  .ui.button.ghost.add-new {
    width: 150px;
    padding: 10px 0;
    margin: 10px 0 20px;
  }
}
