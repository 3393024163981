@import '../../styles/_variables';

.BaseEntry {
  display: flex;
  height: 100vh;

  .loginBg {
    flex: 0 0 50%;
    background: url('../../assets/img/bpt_login_bg.jpg') 0px 0px / cover,
      #464646;
  }

  .vendor-link {
    font-weight: bold;
    color: $primary;
  }

  .recommended-browsers {
    margin-top: 2rem;
  }

  form {
    margin: 3rem auto 0;
    width: 325px;
  }
  .buttonRow {
    width: 200px;
    margin: 3rem auto 0px;
  }
  p {
    margin-bottom: 0;
  }
  .viewContainer {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img.brand {
      position: relative;
      width: 300px;
      align-self: center;

      &.xmas {
        width: 279px;
      }
    }
    img.alt-brand {
      position: absolute;
      bottom: 40px;
      width: 120px;
    }
    .content {
      width: 350px;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      align-items: center;
    }
  }
  .heading,
  .description {
    text-align: center;
  }
  .heading {
    margin-top: 10px;
  }
  .description {
    line-height: 1.7;
  }
}
