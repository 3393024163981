@import '../../styles/_variables.scss';

.Reports {
  .cardBody {
    width: 100%;
    padding-top: 1.5rem;
    min-height: 0 !important;
    flex-direction: column;
  }
  .report-type {
    width: 60%;
  }
  .half-width {
    width: 40%;
  }
  .additional-selector {
    width: 20%;
  }
  .label {
    margin-bottom: 0.5em;
    font-size: 1em;
    color: #474748;
  }
  .main-content {
    display: flex;
    justify-content: space-between;
  }
  .date-pickers {
    display: inline-block;
    padding-left: 20px;

    .date-selector {
      padding-right: 0;
    }
    i {
      margin: 0 10px;
    }
  }
  .filter-footer {
    width: 100%;
    padding: 2rem 0 1rem;
    align-items: center;
    > div {
      flex: 1 1 auto;
    }
    button {
      transition: color 0.25s;
      cursor: pointer;
      border: none;
      color: $darkGray;
      &:hover {
        color: $primary;
      }
    }
    .ui.basic.primary.button {
      background-color: $primary !important;
      color: #fff !important;
      float: right;
    }
    .ui.basic.loading.button:not(.inverted):before {
      // fix the loading color when its disabled
      border-color: #fff;
    }
  }
}
