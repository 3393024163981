@import '../../styles/_variables';

.ui.popup.TableRowOptionPopup {
  margin: 0;
  padding: 0;
  left: 15px !important;
.EditMenu {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    cursor: pointer;
    padding: 5px 10px;
    width: 120px;

    &:hover {
      background: $lighterGray;
    }
  }
}
}
