.EditNoteModal {
  .button-box {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;

    .ui.button {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .member-row {
    margin-top: 10px;

    .ui.checkbox {
      margin-right: 30px;
    }
  }
}
