@import '../../styles/_variables';

#root.hasStickyHeader {
  .headerbar {
    box-shadow: none;
  }
}

#appContent {
  z-index: 0;
  position: relative;
  overflow-y: overlay;
}

.hasSectionHeader {
  padding-top: 80px;
}

.MainLayout {
  display: flex;
  height: 100vh;
  section.contentContainer {
    min-width: 1146px; // 1146px (main content) + 240px (sidebar) = 1366 min width
    flex: 1 1 auto;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background: $offWhite;
    position: relative;
    div.content {
      padding: 1.5rem;
      flex: 1 1 auto;
      overflow: hidden;
      overflow-y: auto;
    }
  }
  .badge.contractTier {
    &.elite {
      color: darken($yellow, 5);
      border-color: $yellow;
    }
    &.pro {
      color: darken($blue, 5);
      border-color: $blue;
    }
    &.essentials {
      color: darken($gray, 5);
      border-color: $gray;
    }
  }
}
