.AddHHMModal {
  .button-box {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;

    .ui.button {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
