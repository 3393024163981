/****************************************************
 * Colors
 ***************************************************/

$blue: #00a9e0;
$black: #474748;
$purple: #6d2077;
$yellow: #ffcd00;
$gray: #929396;
$lightGray: #d4d5d7;
$offWhite: #f2f2f2;
$lightPurple: #bfa9c1;
$red: #d23939;
$green: #a0cf69;
$lighterGray: #eee;

$text: $black;
$disabledText: #a1a3a4;
$mediumGray: $lightGray;
$darkGray: $black;
$mediumLightGray: $lightGray;
$inputBorderGray: $lightGray;
$orange: $red;

$primary: $blue;
$secondary: $purple;
$destructive: $red;
$disabled: #dadada;

$danger: $red;
$accent: $purple;

$success: $green;
$failure: $red;
$info: $purple;

$sidebarFontColor: $lightPurple;

// Color classes
.-bg-primary {
  background: $primary;
}
.-bg-secondary {
  background: $secondary;
}
.-bg-accent {
  background: $accent;
}
.-bg-success {
  background: $success;
}
.-bg-danger {
  background: $danger;
}
.-bg-info {
  background: $info;
}

.-text-primary {
  color: $primary;
}
.-text-secondary {
  color: $secondary;
}
.-text-accent {
  color: $accent;
}
.-text-success {
  color: $success;
}
.-text-danger {
  color: $danger;
}
.-text-info {
  color: $info;
}
.-text-light {
  color: white;
}

/****************************************************
 * Button
 ***************************************************/
button.ui.btn-secondary {
  background: $purple;
  color: #fff;

  &:hover {
    background: darken($purple, 10%);
    color: #fff;
  }
}

/****************************************************
 * Misc.
 ***************************************************/
$borderRadiusBase: 3px;
$boxShadowSoft: 0 4px 8px 0 rgba(55, 93, 112, 0.1);
$boxShadowBigly: -8px 0 18px 0 rgba(0, 0, 0, 0.3);

/****************************************************
 * Typography
 ****************************************************/
$baseFontSize: 16px;
$bodyFontFamily: 'Open Sans', sans-serif;

$flipTransition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
