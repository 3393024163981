.AddApplicationModal {
  .button-box {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;

    .ui.button {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .field-type,
    .field-confirmation {
      width: 280px;
    }

    .field-site,
    .field-plan,
    .field-source,
    .field-app-number,
    .field-category {
      width: 48%;
    }
    .long {
      width: 100%;
    }
  }
}
