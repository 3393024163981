.ClientAdd {
  .cardBody {
    display: block;
    padding-bottom: 30px;
  }
  .date-selector {
    padding: 0;
    height: 34px;
    label {
      padding-top: 0.05em;
      color: #929396;
    }
  }
  .date-selector:not(.disabled) .DayPickerInput input {
    font-size: 14px;
  }
  button.ui.button.ghost.danger.delete {
    margin-top: 1rem;
  }
  .form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &.flex-left {
      justify-content: start;
      > div {
        margin-right: 2%;
      }
    }
    &.not-family {
      .isBptEmployee,
      .isBptEmployeeFamily {
        width: 36.3%;
      }
      .hasInternet {
        width: 23.5%;
      }
    }
    .isBptEmployee {
      width: 18%;
    }
    .isBptEmployeeFamily {
      width: 23%;
    }
    .hasInternet {
      width: 15%;
    }
    .bpt_family_member_name,
    .bpt_family_relationship {
      width: 18%;
    }
    .first_name,
    .last_name {
      width: 23.5%;
    }
    .middle_name,
    .sex {
      width: 5%;
    }
    .dob {
      width: 16%;
    }
    .ssn {
      width: 17%;
    }
    .hoh_rel,
    .pref_lang,
    .raceId,
    .bptAuthorizedRep {
      width: 23.5%;
    }
    .navigator {
      width: 20%;
    }
    textarea {
      height: 10em;
    }
    .quarter {
      width: 100%;
      height: 12em;
      > * {
        width: 100%;
      }
    }
  }
}
