@import '../../styles/_variables.scss';

.Chip {
  font-size: 12px;
  display: inline-block;
  border-radius: 10px;
  padding: 0 5px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  width: 90px;

  &.denied,
  &.incomplete {
    background: $red;
  }
  &.suspended {
    background: #DD801D;
  }
  &.pending {
    background: $gray;
  }
  &.transferred,
  &.withdrawn {
    background: $black;
  }
  &.approved {
    background: $green;
  }
  &.withdrawal {
    background: #474748;
  }
}
