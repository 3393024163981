@import '_variables';

/****************************************************
 * Typography
 ****************************************************/

h1 {
  font-size: 27px;
  font-weight: 500;
}
h2 {
  font-size: 24px;
  font-weight: 500;
}
h3 {
  font-size: 22px;
  font-weight: 500;
}
h4 {
  font-size: 16px;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: $text;
  transition: color 0.25s;
  &:hover {
    color: $primary;
    transition: color 0.5s;
  }
}
button.ui.btn-link {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  background: none;
  color: $primary;

  &:hover,
  &:active,
  &:focus {
    color: $primary;
    background: none;
  }
}

/* this style matches ^ anchor style, but is applied to <button>s*/
button.link {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: $text;
  transition: color 0.25s;
  &:hover {
    color: $primary;
    transition: color 0.5s;
  }

  &.primary {
    color: $primary;
  }
}

.ui.button.ghost {
  background-color: transparent;
  color: $primary;
  transition: background-color 0.1s ease;
  &:hover {
    background-color: rgba($primary, 0.1);
  }
  &:active,
  &:focus {
    background-color: rgba($primary, 0.1);
    color: $primary;
  }

  &.secondary {
    color: $secondary;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba($secondary, 0.1);
    }
  }

  &.dark {
    color: $gray;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba($gray, 0.1);
    }
  }

  &.danger,
  &.destructive {
    color: $destructive;
    &:hover,
    &:active,
    &:focus {
      background-color: rgba($destructive, 0.1);
    }
  }
  &.disabled {
    color: $disabledText;
  }
}

.ui.button {
  &.disabled {
    background-color: $disabled;
    color: $disabledText;
  }
  &.negative {
    background-color: $destructive;
    &:hover,
    &:active,
    &:focus {
      background-color: darken($destructive, 10%);
    }
  }
}

div.errorContainer {
  text-align: center;
  display: block;
  width: 100%;
  max-width: 350px;
  margin-top: 1rem;

  p.error {
    padding: 0.5rem 1.5rem;
    display: block;
    background: $danger;
    color: white;
    transition: all 0.5s;
  }
}

i.page-loader {
  font-size: 2rem;
  color: $primary;
}

.monospace {
  font-family: monospace;
}

/****************************************************
 * Pills
 ****************************************************/
.pill {
  font-size: 0.75rem;
  text-transform: uppercase;
  padding: 0.25em 0.5em;
  border-radius: $borderRadiusBase;
  vertical-align: middle;
  margin-left: 0.5em;
  display: inline;
}

/****************************************************
 * Semantic Tooltips (Popups)
 ****************************************************/
.ui.popup {
  // Chill out the default box shadow
  box-shadow: $boxShadowSoft;
}
.ui.popup.previewPopup {
  background: #323641;
  border: none;
  color: white;
  font-size: 0.8em;
  &:before {
    background: #323641 !important;
  }
}

/****************************************************
 * Toasts
 ****************************************************/
div.toast-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  padding: 1rem;
}

/****************************************************
 * Forms
 ****************************************************/
div.inline.field {
  &:not(:first-child) {
    margin-left: 1em;
  }
  display: inline-block;
  label.inline {
    font-weight: normal !important;
  }
}

input,
textarea {
  outline: none;
  border: 1px solid $inputBorderGray;
  padding: 8px 7px;
  border-radius: $borderRadiusBase;
  &[disabled] {
    color: $text;
    background: $lightGray;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.select-input-display.is-disabled {
  color: $text;
  background: $lightGray;
  &:hover {
    cursor: not-allowed;
  }
}

.validation-error {
  color: $danger;
  font-size: 0.8em;
  padding: 3px 0;
  text-align: right;
}

.input-line-errors-under {
  min-height: 65px;
  input {
    margin-bottom: 5px;
  }
}

input::placeholder {
  font-family: 'fontello', $bodyFontFamily;
}

label {
  display: block;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;

  .full-width {
    width: 100%;
  }

  .half-width {
    width: 48%;
  }

  .third-width {
    width: 30%;
  }

  .fourth-width {
    width: 21%;
  }

  .fifth-width {
    width: 15%;
  }
}

.input-group {
  min-height: 75px;

  input,
  textarea {
    outline: none;
    background-color: $lightGray;
    border: 1px solid $inputBorderGray;
    margin-top: 5px;
    padding: 0.72rem;
    margin-bottom: 0px;
    border-radius: $borderRadiusBase;

    &:focus {
      background-color: $mediumLightGray;
    }
  }

  &.has-error,
  .has-error {
    input {
      border: 1px solid $danger;
    }
    label,
    input {
      color: $danger;
    }
  }

  .input-group {
    min-height: 59px;
  }

  &.has-error {
    input,
    .select-input-display {
      border: 1px solid $danger;
    }
    label,
    input {
      color: $danger;
    }
  }

  .option-overlay {
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }
}

.select-input {
  margin-top: 5px;
  position: relative;

  .select-input-display,
  .select-input-option {
    background-color: $lightGray;
    padding: 0.72rem;
    cursor: pointer;
  }

  .select-input-display {
    outline: none;
    border-radius: $borderRadiusBase;

    &:focus {
      background: $mediumLightGray;
    }

    span {
      display: inline-block;
      height: 1em;
    }

    i.icon-angle-down {
      position: absolute;
      right: 0;
      font-size: 20px;
      line-height: 20px;
    }
  }

  .select-input-option {
    background-color: $lightGray;

    &:hover,
    &.selected {
      background-color: $mediumLightGray;
    }
  }

  .option-container-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .option-container {
    position: absolute;
    border-radius: $borderRadiusBase;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 200;
    // box-shadow: $boxShadowSoft;
    // box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 2px;
  }
}

h4.form-sub-heading {
  font-weight: 800;
  font-size: 1.15em;
  margin-bottom: 20px;
}

.input-group.no-label {
  min-height: 40px;
  height: 40px;
}

/****************************************************
 * Semantic Inputs
 ****************************************************/
// .ui.input {
//   &.withBackground {
//     border: 2px solid $mediumLightGray;
//     padding: 0.25em 0.5em;
//     &:after {
//       display: none;
//     }
//     &.monospace {
//       input {
//         font-family: monospace;
//       }
//     }
//     &.centered {
//       input {
//         text-align: center;
//       }
//     }
//     input {
//       border: none;
//       padding: 0;
//     }
//   }
// }

/****************************************************
 * Wells
 ****************************************************/
.well {
  background: $lightGray;
  color: $text;
  text-align: center;
  padding: 1rem;
  min-width: 500px;
  display: inline-block;
  border-radius: $borderRadiusBase;
  margin: 0 auto;
}

/****************************************************
 * Semantic Tables
 ****************************************************/
div.DataTable {
  position: relative;
  width: 100%;
  .filterRow {
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-end;
    & > * {
      margin-left: 0.75rem;
    }
    :first-child {
      margin-left: 0;
    }
  }
  .pagination {
    position: relative;
    display: block;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    div.ui.pointing.secondary.menu {
      border-bottom: none;
    }
  }
  .well {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

table.ui.selectable {
  tr:hover {
    cursor: pointer;
  }
}
.ui.table td.collapsing,
.ui.table th.collapsing {
  width: 1px;
  white-space: nowrap;
}

/****************************************************
 * Semantic Tabs
 ****************************************************/
div.ui.pointing.secondary.menu {
  border-bottom: 1px solid $text;
  a.item {
    color: $text;
    font-weight: normal;
    &.active,
    &.active:hover {
      color: $primary;
      border-bottom: 3px solid $primary;
    }
  }
}

/****************************************************
 * Semantic Switch
 ****************************************************/
.ui.toggle.checkbox {
  input:focus ~ label {
    &:before {
      border: 1px solid $mediumLightGray;
    }
  }
  input ~ label {
    &:active {
      outline: none;
      border: none !important;
    }
  }
  label {
    &:before {
      border: 2px solid $green;
    }
  }
  input:checked ~ label {
    &:before {
      background-color: $green !important;
      border: 1px solid $green !important;
    }
    &:hover {
      &:before {
        border: 1px solid $green;
      }
    }
  }
  input:focus ~ label:before {
    background: $mediumLightGray;
    border: 1px solid $mediumLightGray;
  }
  input:focus:checked ~ label:before {
    background-color: $green !important;
  }
  input ~ label {
    &:hover {
      &:before {
        background: $mediumLightGray;
        border: 1px solid $mediumLightGray;
      }
    }
    &:before {
      border: 1px solid $mediumLightGray;
      background: $mediumLightGray;
    }
    &:focus,
    &:active {
      border: 1px solid $mediumLightGray;
    }
  }
}

.button-box {
  button:not(:first-child) {
    margin-left: 0;
    margin-right: 25px;
  }

  &.align-right {
    button:not(:first-child) {
      margin-left: 25px;
      margin-right: 0;
    }
  }
}

/****************************************************
 * Semantic Statistic
 ****************************************************/
.ui.statistic {
  .value {
    padding: 0.5rem 1rem;
    font-weight: bold;
    height: 100%;
    small {
      display: block;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: normal;
      text-transform: lowercase;
    }
  }
  &.horizontal {
    .label {
      padding: 0.5rem 1rem;
      text-align: left;
      text-transform: none;
      margin-left: 0 !important;
    }
  }
}

/****************************************************
 * Semantic Forms
 ****************************************************/
form.ui.form {
  .field {
    &.error {
      margin-bottom: 0;
      // .ui.input {
      //   input {
      //     background-color: transparent;
      //     border-bottom-color: $red !important;
      //   }
      // }
    }
  }
}

div.formError {
  color: $orange;
  font-size: 0.8em;
  margin-bottom: 1rem;
}

/****************************************************
 * Semantic Message
 ****************************************************/
.ui.message {
  &.icon {
    & > .icon:not(.close) {
      font-size: 1.5em;
    }
  }
  .header:not(.ui) {
    font-size: 0.875rem;
  }

  &.negative {
    background-color: $destructive;
  }
}

/****************************************************
 * Semantic Modal
 ****************************************************/
.ui.modal {
  margin-top: 0px !important;
  margin-left: auto;
  margin-right: auto;
  & > .header:not(.ui) {
    font-weight: normal !important;
    padding: 2.5rem 2.5rem 0.5rem;
    font-size: 1.25rem;
  }
  // Fix usage of loader in modal
  .ui.loader:before {
    border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
  }
  .ui.loader:after {
    border-color: #767676 transparent transparent !important;
  }
  & > .content {
    font-size: 0.875rem;
    line-height: 1.7;
    padding: 1.5rem 2.5rem;
  }
  & > .actions {
    padding: 0.5rem 2.5rem 2.5rem;
  }
}

/****************************************************
 * ReCharts Bar Charts
 ****************************************************/
div.recharts-wrapper.chart.barChart
  svg.recharts-surface
  g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
  g.recharts-cartesian-axis-ticks
  g.recharts-layer.recharts-cartesian-axis-tick
  text.recharts-text.recharts-cartesian-axis-tick-value
  tspan {
  // customize x-axis labels
}

/****************************************************
 * ReCharts
 ****************************************************/
.recharts-sector {
  stroke: none !important;
}

/***** Line Charts *****/

/* Fancy drop shadow */
.recharts-surface:hover {
  .recharts-line-curve {
    transition: filter 0.25s;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
  }
}

.recharts-line-curve {
  transition: filter 0.25s;
}

/* Tick colors and size */
.line-chart-container {
  .recharts-cartesian-axis-tick-value {
    fill: $gray;
    font-size: 0.8em;
  }
}

/***** Common *****/
.recharts-cartesian-axis {
  .recharts-label {
    fill: $gray;
    font-size: 0.8em;
  }
}

/******************* Start Styles ******************/

* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

div#root {
  min-height: 100vh;
  position: relative;
}

.align-right {
  float: right;
  justify-content: flex-end;
}

.align-left {
  float: left;
  justify-content: flex-start;
}

.justify-self-right {
  justify-self: flex-end;
}

.justify-self-left {
  justify-self: flex-start;
}

/* FIX Semantic UI Modal issue */
.ui.page.modals.transition.visible {
  display: flex !important;
}
.ui.dimmer {
  background-color: rgba(#323641, 0.8);
}
.ui.modal {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  > .header {
    border-bottom: none;
    font-weight: 600;

    &:not(.ui) {
      font-weight: 600;
    }
  }
  > .actions {
    border-top: none;
    background: none;
    text-align: center;
  }
}

/****************************************************
 * Transitions
 ****************************************************/
.routeTransition-enter,
.routeTransition-appear {
  opacity: 0;
  transition: all 500ms ease-in-out;
}
.routeTransition-enter-done,
.routeTransition-appear-done {
  opacity: 1;
  transition: all 500ms ease-in-out;
}
.routeTransition-exit {
  opacity: 0;
  transition: all 500ms ease-in-out;
}
.routeTransition-exit-active {
  opacity: 0;
  transition: all 500ms ease-in-out;
}
.routeTransition-exit-done {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

// TABs
div.ui.pointing.secondary.menu {
  border-bottom: 2px solid $offWhite;
}
div.ui.pointing.secondary.menu a.item.active,
div.ui.pointing.secondary.menu a.item.active:hover {
  border-bottom: 2px solid #00a9e0;
}
