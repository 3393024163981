.EditUserModal {
  .header {
    font-size: 20px;
  }

  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .half-width {
    width: 45%;
  }
  .full-width {
    width: 100%;
  }
 
  .button-box{
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    text-align: right;

    button.ui.button {
      margin-right: 0;
      margin-left: 10px;
    }
  }


}
