@import '../../styles/_variables';

.Clients {
  .Card {
    .Card {
      box-shadow: none;
      .cardBody {
        padding: 0;
      }
    }
    .empty-text {
      text-align: center;
      padding: 30px 0;
      color: $gray;
    }
  }

  button.ui.button.link {
    color: #00a9e0;
    text-decoration: underline;
    background: rgba(0, 0, 0, 0) !important;
    padding: 0;
    margin: 0;
  }

  .ui.segment {
    width: 100%;
    box-shadow: none;
    border: 0;
    padding: 0;
  }
  div.ui.pointing.secondary.menu {
    width: 100%;

    border-bottom: 1px solid $offWhite;

    a.item {
      color: $gray;
    }
  }
}

.ui.popup.EditClientMenuPopup {
  margin: 0;
  padding: 0;
  left: 15px !important;
}
.EditClientMenu {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    cursor: pointer;
    padding: 5px 10px;
    width: 120px;

    &:hover {
      background: $lighterGray;
    }
  }
}
