@import '../../styles/variables';

.FormField {
  margin-bottom: 1.5rem;
  flex: 1 1 auto;

  .label {
    font-size: 11px;
    color: $gray;
  }
  .value {
    a {
      color: $primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  textarea {
    padding: 0.75rem 0;
    border-bottom: 1px solid $darkGray;
  }

  .ui.selection.dropdown {
    min-width: 2rem;
    width: 100%;
    div.item {
      min-height: 37px;
    }
  }
  .error-message {
    display: none;
    font-size: 12px;
  }

  &.error {
    .label,
    .icon,
    .error-message {
      color: $orange;
    }
    .component,
    .value {
      border-color: $orange;
    }
    .error-message {
      display: block;
    }
  }
}
