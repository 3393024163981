@import '../../styles/_variables';

.Users {
  .Card {
    .Card {
      box-shadow: none;
      .cardBody {
        padding: 0;
      }
    }
    .empty-text {
      text-align: center;
      padding: 30px 0;
      color: $gray;
    }
    .well {
      background: #fff;
    }
  }

  .ui.segment {
    width: 100%;
    box-shadow: none;
    border: 0;
    padding: 0;
  }
  div.ui.pointing.secondary.menu {
    width: 100%;

    border-bottom: 1px solid $offWhite;

    a.item {
      color: $gray;
    }
  }
}
