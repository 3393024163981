@import '../../styles/_variables';

.TabComposition {
  .Card {
    box-shadow: none;
    padding-top: 20px;
    header {
      padding: 0;
    }
    .cardBody {
      padding: 0;
    }
  }
  &.ui.segment {
    width: 100%;
    box-shadow: none;
    border: 0;
    padding: 0;
  }
  .btn-secondary {
    z-index: 100;
    position: relative;
  }
}
