@import '../../styles/_variables';

.EditApplication {
  .FormDate {
    min-width: 200px;
  }
  .col-1 {
    float: left;
    width: 24%;
  }
  .col-2 {
    float: right;
    width: 74%;
  }
  .cardBody {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .Card {
    margin-bottom: 20px;
  }
  .form-row {
    margin-bottom: 20px;
    width: 100%;
    display: flex;

    &.float-left {
      justify-content: flex-start;
    }

    & > .FormFieldBase {
      margin-right: 20px;
    }

    & > :last-child {
      margin-right: 0;
    }

    .third-width {
      width: 32%;
    }
    .half-width {
      width: 49%;
    }
    .half-width .DayPickerInput input {
      width: 100%;
    }
  }
}
