@import '../../styles/_variables.scss';
.ClientDetails {
  .cardBody {
    display: block;
  }
  .overview {
    margin-bottom: 20px;
  }
  .form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    clear: both;

    & .FormField {
      float: left;
      width: 19%;
    }
    .upcase .title {
      text-transform: uppercase;
    }
    .link .value {
      display: inline-block;
      color: #00a9e0;
      text-decoration: underline;
      cursor: pointer;
    }
    .multiline .value {
      white-space: pre;
    }
  }
}
