@import '../../styles/_variables';
section.sidebar {
  flex: 0 0 190px;
  padding: 1rem 0;
  overflow: auto;
  background-color: $secondary;
  position: relative;
  .background-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 500px;
    background-image: url('../../assets/img/bpt_login_bg.jpg');
    background-position: bottom -90px right 50%;
    background-size: 250%;
    background-repeat: no-repeat;
    box-shadow: inset 0 60px 30px -20px $secondary;
    opacity: 0.5;
  }
  .bottom-logo {
    width: 100%;
    height: 110px;
    position: absolute;
    bottom: 0;
    background-image: url('../../assets/img/ckf_logo_white.svg');
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .SidebarFeature {
    .icon {
      margin-right: 15px;
    }
  }

  .brand {
    z-index: 1;
    position: relative;
    text-align: center;
    img {
      width: 150px;
      display: inline-block;
    }
    nav#main {
      margin-top: 2rem;
      a {
        padding: 0.5rem 2rem;
        font-size: 14px;
        display: block;
        text-align: left;
        color: $sidebarFontColor;
        transition: all 0.23s ease-in-out;
        outline: none;
        position: relative;
        &.active {
          font-weight: 500;
          color: $yellow;
        }

        &:hover {
          color: lighten($sidebarFontColor, 20%);
        }
      }
      section {
        margin-top: 1rem;
        padding-top: 1rem;
        label {
          color: darken($secondary, 20%);
          text-transform: uppercase;
          font-size: 14px;
          text-align: left;
          padding: 0.5rem 2rem;
          font-weight: 500;
          letter-spacing: 1.5px;
        }
      }
    }
  }
}
