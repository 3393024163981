@import '../../styles/_variables';

.FormFieldBase {
  display: inline-block;

  &.fluid {
    width: 100%;
  }

  input,
  textarea {
    appearance: none;
    outline: none;
    color: $black;
    font-size: 14px;
    box-sizing: border-box;
    border: none;
    width: 100%;
    padding: 7px 8px;
  }
  .icon {
    color: $black;
    margin-right: 8px;
    align-self: center;
    line-height: 1 !important;
  }
  .component {
    display: flex;
    justify-content: space-between;
    border: 1px solid $inputBorderGray;
    border-radius: 2px;
    background: #fff;
    position: relative;
  }
  .DayPickerInput-Overlay {
    top: 4px;
  }

  .label {
    font-size: 12px;
    color: $gray;
    position: relative;
    margin-bottom: 2px;

    &.required:after {
      margin: -0.2em 0 0 0.2em;
      content: '\2022';
      color: #e74a2c;
      display: inline-block;
      vertical-align: top;
    }
  }

  &:focus-within {
    .component {
      border-color: $primary;
    }
    input,
    textarea {
      z-index: 100;
    }
  }

  .error-message {
    display: none;
    font-size: 12px;
  }

  &.error {
    .label,
    .icon,
    .error-message {
      color: $orange;
    }
    .component {
      border-color: $orange;
    }
    .error-message {
      display: block;
    }
  }

  &.disabled {
    .label {
      color: $gray;
    }
    input,
    textarea,
    .icon {
      color: rgba($darkGray, 0.5);
    }
    .component {
      border-color: $inputBorderGray;
      background: $lightGray;
    }
    .error-message {
      display: hidden;
    }
  }

  .options-container {
    z-index: 100;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 34px;
    left: 0px;
    background: #fff;
    box-shadow: $boxShadowSoft;
    border-radius: 2px;
    width: 100%;

    li {
      padding: 8px;
      cursor: pointer;

      &:hover {
        background: $lightGray;
      }

      &.focused {
        background: $lightGray;
      }
      &.selected {
        background: $mediumLightGray;
      }
    }
  }

  &.FormSelect {
    .icon {
      cursor: pointer;
    }

    &.disabled {
      .icon {
        cursor: default;
      }
    }
  }
}
