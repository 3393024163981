@import '../../styles/_variables';
.date-selector {
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  display: inline-block;
  width: 100%;
  &.start-date {
    margin-right: 1.5rem;
  }
  .DayPickerInput {
    width: 100%;
    position: relative;
    input {
      display: flex;
    }
  }
  &:not(.disabled) {
    .DayPickerInput {
      &:after {
        font-family: 'fontello';
        content: '\e801';
        position: absolute;
        top: 50%;
        right: 9px;
        transform: translateY(-50%);
      }
      input {
        padding-right: 1em !important;
      }
    }
  }
  .DayPickerInput-Overlay {
    width: 235px;
  }
  &.align-right .DayPickerInput-Overlay {
    left: initial;
    right: 0;
  }

  label {
    padding-bottom: 0.5em;
    font-size: 1em;
    font-weight: normal;
    color: #474748;
    line-height: 1;
  }
}

.badDate input {
  color: $danger;
}
